import { Link } from 'react-router-dom';
import gbaLogo from '../../util/Images/gbaLogo.png';
import logo from '../../util/Images/logo.svg';
import { scrollToTopFunc } from '../../redux/actions/dataActions';
import { useTranslation } from 'react-i18next';

function Footer() {
	const { t } = useTranslation();
	return (
		<div>
			<div className='footerContainer '>
				<h2 className='slogan center mgtOnly notranslate sloganFooter white'>
					Every person has a legacy™
				</h2>
				<hr className='max600' />
				<div className='flex center pdt'>
					<a
						href='https://play.google.com/store/apps/details?id=com.goodbyeapp.twa'
						target='_blank'
						rel='noreferrer'
						tabIndex='0'
					>
						<img
							src='https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png'
							width={190}
							alt='Play Store'
						/>
					</a>
				</div>
				<div className='footerLinks'>
					<div className='footerNav'>
						<div className='footerNavItems'>
							<h3 className='underline textTitle mgYH bold'>
								{t('contact_us')}
							</h3>
							<Link onClick={() => scrollToTopFunc()} to='/contact'>
								{t('contact')}
							</Link>
							<Link onClick={() => scrollToTopFunc()} to='/report'>
								{t('report_a_problem')}
							</Link>
							<Link onClick={() => scrollToTopFunc()} to='/faq'>
								{t('FAQ')}
							</Link>
						</div>
					</div>
					<div className='footerNav'>
						<div className='footerNavItems'>
							<h3 className='underline textTitle mgYH bold'>{t('about_us')}</h3>
							<Link onClick={() => scrollToTopFunc()} to='/logo'>
								{t('our_logo')}
							</Link>
							<Link onClick={() => scrollToTopFunc()} to='/slogan'>
								{t('our_slogan')}
							</Link>
							<Link onClick={() => scrollToTopFunc()} to='/web'>
								{t('website')}
							</Link>
						</div>
					</div>
				</div>
				<div className='footerLinks'>
					<div className='footerNav'>
						<div className='footerNavItems'>
							<h3 className='underline textTitle mgYH bold'>{t('useful')}</h3>
							<Link onClick={() => scrollToTopFunc()} to='/enableCamera'>
								{t('camera')}
							</Link>
							<Link onClick={() => scrollToTopFunc()} to='/enableMicrophone'>
								{t('microphone')}
							</Link>
							<Link onClick={() => scrollToTopFunc()} to='/enableNotifications'>
								{t('notifications')}
							</Link>
							<Link onClick={() => scrollToTopFunc()} to='/download'>
								{t('download')}
							</Link>
						</div>
					</div>
					<div className='footerNav'>
						<div className='footerNavItems'>
							<h3 className='underline textTitle mgYH bold'>{t('policies')}</h3>

							<Link onClick={() => scrollToTopFunc()} to='/cookie-use'>
								{t('cookie_use')}
							</Link>
							<Link onClick={() => scrollToTopFunc()} to='/privacy-policy'>
								{t('privacy_policy')}
							</Link>
							<Link onClick={() => scrollToTopFunc()} to='/terms-of-service'>
								{t('terms_of_service')}
							</Link>
							<Link
								onClick={() => scrollToTopFunc()}
								to='/community-guidelines'
							>
								{t('community_guidelines')}
							</Link>
						</div>
					</div>
				</div>

				<Link to='/'>
					<div
						className='flex alignItemsCenter center'
						onClick={() => scrollToTopFunc()}
					>
						<div className='smallBackgroundLogo mgr'>
							<img className='tinyLogo' src={logo} alt='Logo' />
						</div>
						<img className='footerGBLogo' src={gbaLogo} alt='Goodbye App' />
						<small className='topTranslate8 bold white'>®</small>
					</div>
				</Link>
				<hr className='max600' />
				<section className='socialMediaLogos'>
					<a
						rel='noopener noreferrer'
						href='https://www.facebook.com/goodbyeapp'
						target='_blank'
						aria-label='Facebook'
					>
						<img
							className='transparent socialMediaLogoSize facebook'
							src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/icons%2FwhiteFB.png?alt=media&token=358df592-273c-4191-899a-6c814c554c7a'
							alt='facebook'
						/>
					</a>
					<a
						rel='noopener noreferrer'
						href='https://www.instagram.com/goodbyeappofficial/'
						target='_blank'
						aria-label='Instagram'
					>
						<img
							className='transparent socialMediaLogoSize instagram'
							src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/icons%2FwhiteInstagram.png?alt=media&token=10f3f0ca-6a7a-47e4-a869-8f7fe443a238'
							alt='instagram'
						/>
					</a>
					<a
						rel='noopener noreferrer'
						href='https://www.youtube.com/channel/UCeAG5cAQLzfD8Zgk_-Uzqnw'
						target='_blank'
						aria-label='Youtube'
					>
						<img
							className='transparent socialMediaLogoSize youtube'
							src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/icons%2FwhiteYT.png?alt=media&token=544848cd-d72c-42ec-8dd3-78070b468cb7'
							alt='youtube'
						/>
					</a>
					<a
						rel='noopener noreferrer'
						href='https://twitter.com/goodbyeapp'
						target='_blank'
						aria-label='TwitterX'
					>
						<img
							className='transparent socialMediaLogoSize twitterX'
							src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/icons%2FwhiteTwitterx.png?alt=media&token=cde4c88b-187b-455e-bb1d-974ec0dc27a1'
							alt='x-twitter'
						/>
					</a>
					<a
						rel='noopener noreferrer'
						href='https://www.linkedin.com/company/goodbyeapp/'
						target='_blank'
						aria-label='LinkedIn'
					>
						<img
							className='transparent socialMediaLogoSize linkedin'
							src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/icons%2FwhiteLinkedin.png?alt=media&token=633de46b-3c2b-40cf-b8ce-4895d0e7c2d9'
							alt='linkedin'
						/>
					</a>
					<a
						rel='noopener noreferrer'
						href='https://www.tiktok.com/@goodbyeapp'
						target='_blank'
						aria-label='Tiktok'
					>
						<img
							className='transparent socialMediaLogoSize tiktok'
							src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/icons%2FwhiteTiktok.png?alt=media&token=2f5ff1a0-7b2e-4ab2-a94b-25d36ec0fd51'
							alt='tiktok'
						/>
					</a>
				</section>

				<small className='animation notranslate'>
					© Copyright 2025 Goodbye App®, Corporation
				</small>
				<div className='mobileNavHeight'></div>
			</div>
			<div className='navMobileDiv'></div>
		</div>
	);
}

export default Footer;
