import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	deletePost,
	showReportedPost,
	setPostDialogData,
	setPostToEdit,
	numberFormatter,
	scrollToTopFunc,
} from '../../redux/actions/dataActions';
import PropTypes from 'prop-types';
import LikeButton from '../SocialComponents/LikeButton';
import dayjs from 'dayjs';
import DottedMenu from '../SocialComponents/DottedMenu';
import ForumIcon from '@mui/icons-material/Forum';
import PublicIcon from '@mui/icons-material/Public';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import BlurOnRoundedIcon from '@mui/icons-material/BlurOnRounded';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TodayIcon from '@mui/icons-material/Today';
import TimerIcon from '@mui/icons-material/Timer';
import BrowseGalleryIcon from '@mui/icons-material/BrowseGallery';
import { LinearProgress } from '@mui/material';
import { withRouter } from '../../redux/withRouter';
import Progress from '../SocialComponents/Progress';
import TimerCountdown from '../PostTimer/TimerCountdown';
import CustomAlerts from '../SocialComponents/CustomAlerts';
import CopyText from '../SocialComponents/CopyText';
import countdown from '../Countdownjs/countdown';
import CustomButton from '../SocialComponents/CustomButton';
import ImgSrc from '../SocialComponents/ImgSrc';
import VideoPlayer from '../MediaPlayers/VideoPlayer';
import AudioPlayer from '../MediaPlayers/AudioPlayer';
import CustomTooltip from '../SocialComponents/CustomTooltip';
import LinkFormatter from '../SocialComponents/LinkFormatter';
import CustomReactReadMoreReadLess from '../ReadMore/CustomReadMoreReadLess';
import Media from 'react-media';
import { t } from 'i18next';

class Post extends Component {
	state = {
		showReleaseCalc: false,
		imageNotFound: false,
		showAll: false,
		loaded: false,
		isVertical: false,
		useOriginalImgUrl: false,
	};

	deletePost = () => {
		this.props.deletePost(this.props.post.postId, this.props.post.livePost);
	};
	showReleaseCalculation = (boolean) => {
		this.setState({ showReleaseCalc: boolean });
	};

	openPostDialog = (
		userHandle,
		postId,
		livePost,
		adminPanelActive,
		fromIcon,
	) => {
		if (this.props.data.loadingDeletePost.includes(postId)) {
			return;
		}
		this.props.setPostDialogData(this.props.post);
		let collection = livePost ? 'post' : 'unreleasedPost';
		const urlLocation = this.props.location.pathname.split('/')[1];
		if (urlLocation === 'users') {
			this.props.history(
				`/${urlLocation}/${userHandle}/${collection}/${postId}`,
			);
		} else if (urlLocation === 'feed' || adminPanelActive) {
			this.props.history(`/${urlLocation}/${collection}/${postId}`);
		}

		if (
			fromIcon &&
			this.props.post.commentCount > 0 &&
			!this.props.data.removedPostIds.includes(postId)
		) {
			setTimeout(() => {
				document
					.getElementById(
						this.props.UI.mode === 'dark' ? 'darkCommentInput' : 'commentInput',
					)
					.focus();
			}, 1000);
		}
	};

	showReportedPost = (postId) => {
		this.props.showReportedPost(postId);
	};

	setPostForEdit = () => {
		let post = {
			...this.props.post,
			showVisibility: true,
		};
		this.props.setPostToEdit(post);
	};

	image404 = () => {
		if (!this.state.useOriginalImgUrl && this.props.post.originalImgUrl) {
			this.setState({
				useOriginalImgUrl: true,
			});
		} else if (this.state.useOriginalImgUrl && this.props.post.originalImgUrl) {
			this.setState({
				useOriginalImgUrl: false,
			});
		} else if (!this.state.imageNotFound) {
			this.setState({
				imageNotFound: true,
			});
		}
	};
	handleReadAll = () => {
		this.setState((prevState) => ({
			showAll: !prevState.showAll,
		}));
		if (!this.state.showAll) {
			const element = document.getElementById(
				`showLess/${this.props.post.postId}`,
			);
			const offsetTop = element.offsetTop;
			window.scrollTo({
				top: offsetTop,
				behavior: 'instant',
			});
		}
	};

	calculateAspectRatio = (width, height) => {
		this.setState({
			loaded: true,
		});
		const isVertical = width / height;
		if (isVertical < 1) {
			this.setState({
				isVertical: true,
			});
		}
	};
	render() {
		const {
			post: {
				userId,
				body,
				createdAt,
				userImage,
				userThumbnail,
				userImageColor,
				userHandle,
				postId,
				likeCount,
				commentCount,
				fileType,
				fileUrl,
				fileType2,
				fileUrl2,
				publicPost,
				releaseDate,
				prevReleaseDate,
				recipients,
				emailRecipients,
				previewPost,
				livePost,
				liked,
				viewed,
				infringements,
				replyCount,
				mentions,
				hashtags,
				originalImgUrl,
				admin,
				appealable,
				deleted,
			},
			data: {
				removedPostIds,
				errorDeletingPost,
				loadingDeletePost,
				editingPostProgress,
				editingPost,
				reportedPosts,
				loadingPostInfringement,
				errorPostInfringement,
				reportingPostId,
				errorEditingPost,
			},
			user: {
				credentials: { handle, imageUrl, thumbnail, imageColor, email },
				adminPanel: { adminPanelActive },
			},
			UI: { mode },
			makePostFunctionality,
		} = this.props;
		const darkMode = mode === 'dark';
		const {
			isVertical,
			showReleaseCalc,
			imageNotFound,
			showAll,
			loaded,
			useOriginalImgUrl,
		} = this.state;
		const ownPost = userId === this.props.user.credentials.userId;
		const imgUrl = ownPost ? imageUrl : userImage;
		const thumbUrl = ownPost ? thumbnail : userThumbnail;
		const dataHandle = ownPost ? handle : userHandle;
		const imgColor = ownPost ? imageColor : userImageColor;
		const isAdmin = ownPost ? this.props.user.credentials.admin : admin;
		const reportedPost = reportedPosts.includes(postId);
		const commentsPlusReplies = commentCount + replyCount;
		const recipientsLength = recipients ? recipients.length : 0;
		const emailRecipientsLength = emailRecipients ? emailRecipients.length : 0;
		const allRecipientsLength = recipientsLength + emailRecipientsLength;
		const canDownload =
			fileType !== 'text' &&
			((recipients &&
				recipients.includes(this.props.user.credentials.userId)) ||
				(emailRecipients && emailRecipients.includes(email)) ||
				handle === dataHandle)
				? true
				: false;

		return reportedPost ? (
			<div id='postCard' className='center'>
				<CustomAlerts
					success={t('report_success', {
						content: t('publication'),
					})}
					margin='0'
				/>
				<CustomButton
					onClick={() => this.showReportedPost(postId)}
					btnText={t('show_content', {
						content: t('publication'),
					})}
					margin='1rem auto 0 auto'
				/>
			</div>
		) : (
			<div id='postCard'>
				{!makePostFunctionality &&
					(adminPanelActive || this.props.user.credentials.admin) && (
						<div>
							<CustomAlerts
								info={true}
								alertId='smallMuiAlert'
								noCenter
								message={
									<div className='flex'>
										<CopyText
											noMargin
											info={true}
											text={postId}
											frase='Post ID'
											small
										/>
										{adminPanelActive && (
											<small className='pdlH'>
												/ Public: {publicPost ? 'True' : 'False'} / Recip.:{' '}
												{recipients && recipients.length}
												Email Recip.:{' '}
												{emailRecipients && emailRecipients.length}
											</small>
										)}
									</div>
								}
								noMargin
							/>
						</div>
					)}
				{!publicPost &&
					userId !== this.props.user.credentials.userId &&
					((recipients &&
						recipients.includes(this.props.user.credentials.userId)) ||
						(emailRecipients &&
							emailRecipients.includes(this.props.user.credentials.email))) && (
						<CustomAlerts
							info={true}
							message={
								allRecipientsLength === 1
									? t('only_you_can_view')
									: allRecipientsLength === 2
									? t('you_and_1_can_view')
									: t('you_and_more_can_view', {
											number: allRecipientsLength,
									  })
							}
							noMargin
							icon={<VisibilityIcon fontSize='small' />}
							alertId='smallMuiAlert'
						/>
					)}
				{(adminPanelActive || this.props.user.credentials.admin) &&
					reportingPostId === postId && (
						<div>
							<CustomAlerts
								info={
									loadingPostInfringement && infringements
										? `${t('removing')} ${t('infringement')}.`
										: loadingPostInfringement
										? `${t('adding')} ${t('infringement')}.`
										: ''
								}
								noMargin
								alertId='smallMuiAlert'
							/>
							<CustomAlerts
								error={errorPostInfringement}
								noMargin
								alertId='smallMuiAlert'
							/>
						</div>
					)}
				{loadingDeletePost.includes(postId) && (
					<div>
						<CustomAlerts
							info={true}
							message={t('deleting')}
							noMargin
							alertId='smallMuiAlert'
						/>
						<LinearProgress />
					</div>
				)}
				{livePost && createdAt && showReleaseCalc && (
					<div>
						<CustomAlerts
							success={true}
							message={t('released_after_creation', {
								date:
									new Date(releaseDate).getTime() -
										new Date(createdAt).getTime() <
									3600000
										? countdown(
												new Date(createdAt),
												new Date(releaseDate),
												countdown.MINUTES,
										  ).toString()
										: countdown(
												new Date(createdAt),
												new Date(releaseDate),
												countdown.DECADES |
													countdown.YEARS |
													countdown.MONTHS |
													countdown.WEEKS |
													countdown.DAYS |
													countdown.HOURS,
										  ).toString(),
							})}
							icon={<BrowseGalleryIcon fontSize='small' />}
							noMargin
							alertId='smallMuiAlert'
						/>
						<CustomAlerts
							info={prevReleaseDate}
							message={t('release_date_inactivity', {
								date: dayjs(prevReleaseDate).format('LLLL'),
							})}
							icon={<TimerIcon fontSize='small' />}
							noMargin
							severity='warning'
							alertId='smallMuiAlert'
						/>
						<CustomAlerts
							info={true}
							message={t('created_on', {
								date: previewPost
									? dayjs(createdAt).format('dddd, MMMM D, YYYY')
									: dayjs(createdAt).format('LLLL'),
							})}
							icon={<TodayIcon fontSize='small' />}
							noMargin
							alertId='smallMuiAlert'
						/>
					</div>
				)}
				{((previewPost && releaseDate !== 'now') ||
					(!previewPost &&
						!livePost &&
						releaseDate >= new Date().toISOString())) && (
					<CustomAlerts
						icon={<TodayIcon fontSize='small' />}
						info={true}
						component={
							<div>
								{t('scheduled_for')}{' '}
								<span className='bold'>
									{dayjs(releaseDate).format('LLLL')}
								</span>
							</div>
						}
						message={
							<TimerCountdown
								releaseDate={releaseDate}
								preview={previewPost}
								post={true}
								selectorId={postId}
							/>
						}
						alertId='smallMuiAlert'
						noMargin
						noCenter
					/>
				)}

				{livePost &&
					!publicPost &&
					ownPost &&
					allRecipientsLength > 0 &&
					!loadingDeletePost.includes(postId) && (
						<CustomAlerts
							info={true}
							message={
								<div>
									<b>
										{allRecipientsLength === 1
											? t('1_can_view')
											: t('more_can_view', { number: allRecipientsLength })}
									</b>{' '}
									<span
										onClick={this.setPostForEdit}
										className='underlineHover pointer'
									>
										{t('edit')}
									</span>
								</div>
							}
							noMargin
							icon={<VisibilityIcon fontSize='small' />}
							alertId='smallMuiAlert'
						/>
					)}

				{editingPost.includes(postId) && (
					<Progress
						progress={editingPostProgress}
						title={t('updating')}
						icon={<BlurOnRoundedIcon />}
					/>
				)}
				<CustomAlerts
					error={errorEditingPost.includes(postId)}
					message={t('error_update', {
						content: t('publication'),
					})}
					noMargin
					alertId='smallMuiAlert'
				/>
				<Media
					queries={{
						isMobile: '(max-width: 1064px)',
					}}
				>
					{(matches) => (
						<div
							style={{
								boxShadow: darkMode
									? '#ccc 0px 2px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px'
									: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
							}}
							id={`showLess/${postId}`}
						>
							<div
								className={`pdPostHeader
									${darkMode ? 'postHeaderDark' : ''}
									`}
							>
								<div className='postHeader'>
									<div className='flex alignSelfStart'>
										<Link
											to={
												previewPost || adminPanelActive
													? '#'
													: `/users/${dataHandle}`
											}
											onClick={() => scrollToTopFunc()}
											state={{ data: userId }}
											className='flex'
										>
											<ImgSrc
												img={imgUrl}
												imgColor={imgColor}
												thumbnail={thumbUrl}
												handle={dataHandle}
												css='mediaImgSkeleton'
												className='mgr'
											/>
										</Link>
										<div className='flexColumn'>
											<Link
												to={
													previewPost || adminPanelActive
														? '#'
														: `/users/${dataHandle}`
												}
												onClick={() => scrollToTopFunc()}
												state={{ data: userId }}
											>
												<p
													className={`notranslate bold textTitle mg0 ${
														darkMode ? 'white' : 'dark'
													}`}
												>
													{dataHandle}
													{isAdmin && (
														<VerifiedUserIcon
															id='verifiedBadgePost'
															fontSize='small'
														/>
													)}
												</p>
											</Link>
											<div className='flexColumn'>
												{!livePost && createdAt && (
													<small>{dayjs(createdAt).format('LLLL')}</small>
												)}
												{!previewPost && livePost && releaseDate && (
													<small>{dayjs(releaseDate).format('LLLL')}</small>
												)}
												{previewPost && releaseDate === 'now' && (
													<small>{dayjs().format('dddd, MMMM D, YYYY')}</small>
												)}
											</div>
										</div>
									</div>

									{previewPost ? (
										<div className='flexColumn alignSelfCenter'>
											{publicPost ? (
												<CustomTooltip title={t('public')} arrow>
													<PublicIcon className='mgbH pointer' />
												</CustomTooltip>
											) : (
												<CustomTooltip title={t('private')} arrow>
													<VpnLockIcon className='mgbH pointer' />
												</CustomTooltip>
											)}
										</div>
									) : (
										<div className='flexColumn'>
											{!removedPostIds.includes(postId) && (
												<DottedMenu
													dataHandle={dataHandle}
													dataUserId={userId}
													deleteFunction={this.deletePost}
													postComponent={true}
													postToEdit={this.props.post}
													comment={false}
													deletedPost={
														removedPostIds.includes(postId) || deleted
															? true
															: false
													}
													postId={postId}
													livePost={livePost}
													canDownload={canDownload && !matches.isMobile}
													infringements={infringements}
													removedPost={
														infringements && appealable !== undefined
													}
												/>
											)}

											{livePost && createdAt && (
												<div className='flex alignSelfCenter bottomTranslate4'>
													<CustomButton
														iconButton
														startIcon={
															<TodayIcon
																id={showReleaseCalc ? 'iconBlue' : ''}
																fontSize='small'
															/>
														}
														color='inherit'
														padding={matches.isMobile ? '0' : '6px'}
														onClick={() =>
															this.showReleaseCalculation(!showReleaseCalc)
														}
														tooltip={
															!showReleaseCalc ? t('scheduled_posts') : ''
														}
														placement='right'
													/>
												</div>
											)}
										</div>
									)}
								</div>

								{body ||
								(mentions && mentions.length > 0) ||
								(hashtags && hashtags.length > 0) ? (
									<div className='pdYH'>
										{(mentions && mentions.length > 0) ||
										(hashtags && hashtags.length > 0) ? (
											<div>
												<p
													className={`notranslate mg0 prewrap ${
														body && body.length > 400 ? 'postBodyContent' : ''
													}  ${showAll ? 'showAllContent' : ''}`}
												>
													<LinkFormatter
														str={body ? body : ''}
														adminPanelActive={adminPanelActive}
														previewPost={makePostFunctionality}
														mentions={mentions}
														hashtags={hashtags}
													/>
												</p>
												{body && body.length > 400 && (
													<div className='flex'>
														<CustomButton
															onClick={this.handleReadAll}
															variant='text'
															btnText={
																showAll ? t('show_less') : t('read_more')
															}
															margin='0 auto -12px auto'
															fontSize='16px'
															height='36px'
															disableRipple
															id='underlineHover'
															textTransform='none'
														/>
													</div>
												)}
											</div>
										) : (
											<p className='mg0 prewrap notranslate'>
												<CustomReactReadMoreReadLess
													charLimit={400}
													readMoreText={t('read_more')}
													readLessText={t('show_less')}
													onClickReadLess={this.handleReadAll}
												>
													{body && body.replace(/\n{3,}/g, '\n\n')}
												</CustomReactReadMoreReadLess>
											</p>
										)}
									</div>
								) : (
									<div className='pdt'></div>
								)}
							</div>

							{fileType === 'image' ? (
								imageNotFound && !removedPostIds.includes(postId) ? (
									<CustomAlerts
										info={t('file_404')}
										noMargin
										icon={<PermMediaIcon />}
										alertId='smallMuiAlert'
									/>
								) : (
									<div
										style={{
											lineHeight: 0,
										}}
									>
										<img
											src={
												useOriginalImgUrl && originalImgUrl
													? originalImgUrl
													: fileUrl
											}
											style={{
												// background: `url(${this.props.post.thumbnail}) no-repeat`,
												backgroundSize: 'cover',
												filter: !loaded && 'blur(3px)',
												maxHeight: !loaded
													? '550px'
													: isVertical && !matches.isMobile
													? '70vh'
													: '80vh',
												width: !loaded ? '100%' : '',
											}}
											onLoad={(e) =>
												this.calculateAspectRatio(
													e.target.naturalWidth,
													e.target.naturalHeight,
												)
											}
											alt={body}
											id='imageContain'
											loading='lazy'
											onError={(event) => {
												event.onerror = null;
												this.image404();
											}}
										/>
									</div>
								)
							) : fileType === 'audio' ? (
								<AudioPlayer
									fileUrl={fileUrl}
									postId={postId}
									isMobile={matches.isMobile}
									makePostFunctionality={makePostFunctionality}
									countViews={livePost}
								/>
							) : fileType === 'video' ? (
								<VideoPlayer
									fileUrl={fileUrl}
									postId={postId}
									makePostFunctionality={makePostFunctionality}
								/>
							) : null}
							{fileUrl2 && fileType2 === 'audio' && (
								<AudioPlayer
									fileUrl={fileUrl2}
									postId={postId}
									isMobile={matches.isMobile}
									makePostFunctionality={makePostFunctionality || !livePost}
									countViews={livePost}
								/>
							)}

							{removedPostIds.includes(postId) ||
							errorDeletingPost.includes(postId) ? (
								<CustomAlerts
									alertId='smallMuiAlert'
									error={
										removedPostIds.includes(postId)
											? t('content_deleted', {
													content: t('publication'),
											  })
											: errorDeletingPost.includes(postId)
											? t('error_deleting_content', {
													content: t('publication'),
											  })
											: t('error')
									}
									noMargin
								/>
							) : !previewPost ? (
								<div className='postActions'>
									{!adminPanelActive && (
										<div className='bottomTranslate2'>
											<LikeButton
												postId={postId}
												liked={liked}
												likeCount={likeCount}
												unreleasedPost={livePost === false}
											/>
											<button
												onClick={() =>
													this.openPostDialog(
														dataHandle,
														postId,
														livePost,
														adminPanelActive,
														true,
													)
												}
												className='commentBtn pdXH pointer'
											>
												<CustomTooltip
													title={t('comment')}
													placement='bottom'
													arrow
												>
													<ForumIcon id='cIcon' />
												</CustomTooltip>
											</button>
										</div>
									)}

									<div className='flex white pointer'>
										{likeCount > 0 && (
											<p
												className='pdrH notranslate bold'
												onClick={() =>
													this.openPostDialog(
														dataHandle,
														postId,
														livePost,
														adminPanelActive,
													)
												}
											>
												{likeCount > 30000
													? numberFormatter(likeCount, 1)
													: likeCount}{' '}
												{likeCount === 1 ? t('like') : t('likes')}
											</p>
										)}

										{commentCount > 0 && (
											<p
												onClick={() =>
													this.openPostDialog(
														dataHandle,
														postId,
														livePost,
														adminPanelActive,
													)
												}
												className='pdrH bold capitalize'
											>
												{commentsPlusReplies > 30000
													? numberFormatter(commentsPlusReplies, 1)
													: commentsPlusReplies}{' '}
												{commentsPlusReplies === 1
													? t('comment')
													: t('comments')}
											</p>
										)}
										{viewed > 0 &&
											(fileType === 'video' ||
												fileType === 'audio' ||
												fileType2 === 'audio') && (
												<CustomTooltip
													title={viewed === 1 ? t('view') : t('views')}
													placement={matches.isMobile ? 'top' : 'right'}
													arrow
												>
													<p className='mg0 flex alignItemsCenter bold'>
														<PlayArrowOutlinedIcon />
														{viewed > 1000
															? numberFormatter(viewed, 1)
															: viewed}{' '}
													</p>
												</CustomTooltip>
											)}
									</div>
								</div>
							) : null}
						</div>
					)}
				</Media>
			</div>
		);
	}
}
Post.propTypes = {
	user: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	post: PropTypes.object.isRequired,
	deletePost: PropTypes.func.isRequired,
	showReportedPost: PropTypes.func.isRequired,
	setPostDialogData: PropTypes.func.isRequired,
	setPostToEdit: PropTypes.func.isRequired,
	numberFormatter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.user,
	data: state.data,
	UI: state.UI,
});
const mapActionsToProps = {
	deletePost,
	showReportedPost,
	setPostDialogData,
	setPostToEdit,
	numberFormatter,
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Post));
