import React from 'react';
import ReactDOM from 'react-dom';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import i18n, { t } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { toast } from 'react-toastify';
import App from './App';
import history from './browserHistory';
import { BrowserRouter } from './BrowserRouter';
import reportWebVitals from './reportWebVitals';
import VerifyToken from './VerifyToken';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundary500 from './pages/ERROR_BOUNDARY/ErrorBoundary500';
import {
	scrollToTopFunc,
	submitErrorBoundary,
} from './redux/actions/dataActions';
import { NEW_VERSION_AVAILABLE } from './redux/types';
import store from './redux/store';

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.use(LanguageDetector)
	.use(HttpApi)
	.init({
		// the translations
		// (tip move them in a JSON file and import them,
		// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
		fallbackLng: 'en',
		detection: {
			// order and from where user language should be detected
			order: [
				'localStorage',
				'htmlTag',
				'cookie',
				'path',
				'subdomain',
				'navigator',
			],
			caches: ['localStorage'],
		},
		backend: {
			loadPath: '/assets/locales/{{lng}}/translation.json',
		},
		react: {
			useSuspense: false,
		},
		supportedLngs: ['en', 'zh', 'es', 'fr', 'ar', 'pt', 'ru', 'ja', 'de', 'uk'],
	});

function logErrorToService(error, info) {
	// Use your preferred error logging service
	console.log('Caught an error:', JSON.stringify(error));
	console.log('Error INFO:', info.componentStack);
	if (window.location.hostname === 'goodbyeapp.com') {
		submitErrorBoundary(JSON.stringify(error), info.componentStack);
		scrollToTopFunc();
	}
}
if ('serviceWorker' in navigator) {
	navigator.serviceWorker.addEventListener('message', (event) => {
		if (event.data.action === 'checkForUpdate') {
			// Check if the PWA is in standalone mode
			const isStandalone = window.matchMedia(
				'(display-mode: standalone)',
			).matches;
			// Check if the service worker is controlling the page
			if (!navigator.serviceWorker.controller) {
				console.log('No active service worker, reloading...');
				window.location.reload();
			} else if (isStandalone) {
				// not the best solution, it will always reload and user might loose progress on installed PWA!
				console.log('Installed PWA detected, forcing hard reload...');
				window.location.href = '/'; // Workaround for reload issues in standalone PWA
				window.location.reload();
			} else {
				console.log('New service worker active, showing update prompt...');
				promptUserToRefresh();
			}
		}
	});
	window.addEventListener('load', () => {
		navigator.serviceWorker
			.register('/service-worker.js')
			.then((registration) => {
				listenForWaitingServiceWorker(registration, promptUserToRefresh);
			})
			.catch((error) => {
				console.log('ServiceWorker registration failed: ', error);
			});
	});
}

function listenForWaitingServiceWorker(reg, callback) {
	function awaitStateChange() {
		reg.installing.addEventListener('statechange', function (e) {
			// console.log(reg.waiting);
			// console.log(e.target.state);
			if (
				e.target.state === 'installed' &&
				reg.waiting &&
				navigator.serviceWorker.controller
			) {
				setTimeout(() => {
					callback(reg);
				}, 1000);
			}
		});
	}

	if (!reg) return;

	if (reg.waiting && navigator.serviceWorker.controller) {
		// console.log('ServiceWorker is waiting.');
		return callback(reg);
	}

	if (reg.installing) {
		// console.log('ServiceWorker is installing.');
		awaitStateChange();
	} else {
		reg.addEventListener('updatefound', () => {
			// console.log('ServiceWorker: Update found.');
			if (reg.installing) awaitStateChange();
		});
	}
}

function reload(reg) {
	if (reg && reg.waiting) {
		reg.waiting.postMessage('skipWaiting');
	}
	window.location.reload();
}

function promptUserToRefresh(reg) {
	toast.info(t('reload_for_new_version'), {
		position: 'top-left',
		autoClose: 10000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: false,
		progress: undefined,
		containerId: 'app',
		icon: <AppShortcutIcon />,
		onClick: () => {
			reload(reg);
		},
		toastId: 'promptUserToRefresh',
	});
	store.dispatch({ type: NEW_VERSION_AVAILABLE });
}

ReactDOM.render(
	<React.StrictMode>
		<VerifyToken />
		<BrowserRouter history={history}>
			<ErrorBoundary
				fallback={<ErrorBoundary500 />}
				onError={logErrorToService}
			>
				<App />
			</ErrorBoundary>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
