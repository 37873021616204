import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { logoutUser } from '../../firebase/firebaseInit';
import PropTypes from 'prop-types';
import { Avatar, Dialog } from '@mui/material';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import WifiOffOutlinedIcon from '@mui/icons-material/WifiOffOutlined';
import { useTranslation } from 'react-i18next';

const Offline = ({ mode }) => {
	const { t } = useTranslation();
	return (
		<div>
			<Helmet>
				<title>Goodbye App</title>
			</Helmet>
			<Dialog open={true}>
				<div className='pdMedia skyBackground center'>
					<div className={`card pd2b1Media ${mode === 'dark' && 'dbc'}`}>
						<Avatar id='cardMainIcon' className='flexAuto'>
							<WifiOffOutlinedIcon id='cardMainIconSize' />
						</Avatar>
						<h1 className='title mg0'>{t('you_are_offline')}</h1>
						<h3 className='subtitle mgbOnly'>{t('activate_wifi')}</h3>
						<CustomAlerts
							info={true}
							severity='warning'
							message={t('save_changes_before')}
							margin='0'
						/>
						<div className='pdbExcept'>
							<small className='website-rights animation'>
								Copyright © 2025 Goodbye App Corporation. All rights reserved.
							</small>
						</div>
					</div>
				</div>
			</Dialog>
		</div>
	);
};

Offline.propTypes = {
	UI: PropTypes.string,
};
const mapStateToProps = (state) => ({
	mode: state.UI.mode,
});

const mapActionsToProps = {
	logoutUser,
};

export default connect(mapStateToProps, mapActionsToProps)(Offline);
