import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	Typography,
} from '@mui/material';
import Footer from '../../components/Footer/Footer';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HelpIcon from '@mui/icons-material/Help';
import CustomAccordion from '../../components/SocialComponents/CustomAccordion';
import { Link } from 'react-router-dom';
import CustomButton from '../../components/SocialComponents/CustomButton';
export default function FAQ() {
	const { t } = useTranslation();
	const mode = useSelector((state) => state.UI.mode);

	return (
		<div>
			<div className='skyBackground'>
				<div className='pdAccordion'>
					<div className={`card pdt ${mode === 'dark' && 'dbc'}`}>
						<Avatar id='cardMainIcon2' className='flexAuto'>
							<HelpIcon id='cardMainIconSize2' />
						</Avatar>
						<h1 className='subtitle bold center mgYH'>
							Frequently Asked Questions
						</h1>
						<CustomAccordion
							// expanded={true}
							title='What is Goodbye App?'
							text='Goodbye App is a unique farewell social media platform that
									allows users to schedule posts and letters up to 30 years in
									the future for their loved ones.'
						/>
						<CustomAccordion
							title='What broadcasting features does Goodbye App offer?'
							text='Goodbye App allows users to upload and share videos, audio messages, and digital content with specific recipients or the general public. These broadcasts can be shared wirelessly over the internet and our social media app.'
						/>
						<CustomAccordion
							title='Can Goodbye App deliver messages to specific recipients?'
							text=' Yes, users can schedule content delivery to specific recipients, ensuring that everything is shared at the right time.'
						/>
						<CustomAccordion
							title='What is the “Release Earlier Protocol”'
							text='The Release Earlier Protocol is a feature that automatically releases unreleased content if a user becomes inactive for a set period. It ensures that important messages are shared even if the user cannot do so themselves.'
						/>
						<CustomAccordion
							title='Does Goodbye App provide multilingual support?'
							text='Yes, Goodbye App currently supports 10 languages, including English, Spanish, French, Japanese, and Arabic, with plans to expand further. Language preferences can be changed in the top right settings panel.'
						/>
						<CustomAccordion
							title='How does Goodbye App ensure safe storage of digital content?'
							text='Goodbye App provides secure cloud-based storage for all uploaded content. Users can access their content anytime and choose when it should be delivered.'
						/>
						<CustomAccordion
							title='Can businesses use it?'
							text='Absolutely. Goodbye App offers businesses tools to promote their services and
									broadcast meaningful content to their audience via the seamless electronic transmission of videos and files across different devices and platforms, including email and social media.'
						/>
						<CustomAccordion
							title='How does Goodbye App help businesses promote their services?'
							text='Goodbye App provides businesses with the ability to create accounts, upload promotional content, and share it with targeted audiences.'
						/>
						<CustomAccordion
							title='Can I use Goodbye App for business networking?'
							text='Yes, Goodbye App supports online business networking by allowing businesses and professionals to connect, share updates, and build meaningful relationships with their audience.'
						/>
						<CustomAccordion
							title='Does Goodbye App provide Business Administration services?'
							text='Yes. Goodbye App provides tools designed to help businesses with administrative functions, such as:'
							component={
								<ul className='smallText'>
									<li>
										<b>Content Scheduling:</b> Businesses can plan and schedule
										their promotional messages in advance.
									</li>
									<li>
										<b>Audience Organization:</b> Businesses can target
										audiences or user groups with their content.
									</li>
									<li>
										<b>Analytics:</b> Goodbye App provides notifications on how
										uploaded content is performing, helping businesses track and
										manage user engagement.
									</li>
									<li>
										<b>File Management:</b> The platform allows businesses to
										upload and organize media files, ensuring their content is
										ready for broadcasting or sharing.
									</li>
								</ul>
							}
						/>
						<CustomAccordion
							title='Does Goodbye App provide Business Management services?'
							text='Definitely. Goodbye App offers a suite of tools to help businesses manage their promotional and marketing efforts, including:'
							component={
								<ul className='smallText'>
									<li>
										<b>Campaign Management:</b> Businesses can create and
										oversee promotional campaigns using custom messages and
										videos.
									</li>
									<li>
										<b>Audience Engagement: </b> Businesses can interact with
										users, share personalized content, and monitor their impact
										on the audience.
									</li>
									<li>
										<b>Account Management:</b> Accounts on Goodbye App can
										organize their activities, manage their online presence, and
										update their branding.
									</li>
								</ul>
							}
						/>
						<CustomAccordion
							title='Does Goodbye App offer SaaS services?'
							text='Yes!'
							component={
								<ul className='smallText'>
									<li>
										<b>Hosted Remotely:</b> Goodbye App is a cloud-based service
										hosted on Firebase and utilizing Google Cloud Platform.
										Goodbye App is accessible from any device with an internet
										connection. Whether you're on your phone, tablet, or
										computer, you can manage your farewell messages from
										anywhere in the world.
									</li>

									<li>
										<b>Continuous Updates:</b> Goodbye App provides ongoing
										updates and improvements without requiring you to manually
										download and install new versions of the software. This is
										typical of SaaS, where the software is maintained and
										updated by the provider seamlessly in the background.
									</li>
									<li>
										<b>Authentication Services:</b> Goodbye App provides a way
										to verify user identity beyond just logging in, for example:
										email verification. We are also implementing 2FA. This
										guarantees that only authorized users can access their
										personal messages and settings. We also prioritize user data
										privacy and compliance with relevant data protection
										regulations. Our authentication services ensure that your
										account is safe, and only you have access to your carefully
										crafted farewell messages.
									</li>
									<li>
										<b>Scalability:</b> Goodbye App is designed to scale as
										needed, utilizing cloud infrastructure that grows with the
										number of users. This ensures that the app performs
										efficiently even as the user base expands, which is a key
										benefit of SaaS solutions.
									</li>
									<li>
										<b>Secure Data Storage:</b> As a cloud-based SaaS, Goodbye
										App stores your data securely in the cloud, ensuring that
										your messages are protected and backed up. We take full
										responsibility for data storage and access security, which
										reduces the need for users to manage it themselves.
									</li>
									<li>
										<b>General Data Protection Regulation (GDPR):</b> Goodbye
										App, as SaaS providers adhere to GDPR compliance measures to
										protect user data.
									</li>
								</ul>
							}
						/>
						<CustomAccordion
							title='How does Goodbye App maintain high-quality service for its users?'
							text='Goodbye App employs a series of quality control measures to ensure that our service is reliable, secure, and delivers a seamless experience for our users. Below are the key ways we maintain high-quality standards:'
							component={
								<ul className='smallText'>
									<li>
										<b>Automated Testing:</b> We use automated tests to catch
										bugs and ensure that new features don’t introduce
										regressions into existing functionality. These tests cover
										various parts of the application, including UI, API
										endpoints, and server-side processes. This helps ensure that
										updates don’t negatively impact user experience.
									</li>

									<li>
										<b>Manual Testing and User Feedback:</b> In addition to
										automated tests, we conduct manual testing to check for edge
										cases, usability issues, and performance bottlenecks. We
										also gather regular feedback from users to identify areas
										where we can improve the app’s functionality or user
										experience.
									</li>
									<li>
										<b>Continuous Integration/Continuous Deployment (CI/CD):</b>{' '}
										Goodbye App is built with a CI/CD pipeline, ensuring that
										code changes are tested and deployed automatically with
										minimal risk. This approach allows us to deliver
										high-quality updates and new features to users faster,
										without compromising on quality. Each change is validated
										before being released to production.
									</li>
									<li>
										<b>Performance Monitoring:</b> We monitor the app’s
										performance in real-time, tracking metrics such as load
										times, uptime, and response times to ensure the app
										functions smoothly at all times. Any performance degradation
										is quickly addressed to prevent service interruptions.
									</li>
									<li>
										<b>Security Audits:</b> Regular security audits are
										conducted to identify potential vulnerabilities in the app.
										This ensures that the platform remains secure for users and
										that data protection protocols are always up to date.
									</li>
									<li>
										<b>Error Reporting and Resolution:</b> Goodbye App has
										built-in error reporting that allows us to quickly detect
										and resolve any issues that arise in production. Our team
										works promptly to address bugs, crashes, or unexpected
										behavior to minimize downtime and provide a stable
										experience.
									</li>
									<li>
										<b>
											User Interface (UI) and User Experience (UX) Optimization:
										</b>{' '}
										We consistently refine the app's interface based on user
										feedback and usability studies. Our goal is to ensure that
										the app is intuitive, responsive, and easy to navigate for
										all users, regardless of their technical expertise.
									</li>
									<li>
										<b>Cloud Scalability Testing:</b> Since Goodbye App is a
										cloud-based SaaS, we test its scalability by simulating high
										traffic and load scenarios. This ensures that our app can
										handle increased demand while maintaining optimal
										performance and preventing any downtime.
									</li>
								</ul>
							}
						/>

						<CustomAccordion
							title='How does Goodbye App ensure data security as part of its SaaS offering?'
							text='Goodbye App takes your privacy seriously. As a SaaS platform, we implement robust security measures to protect your data, including:'
							component={
								<ul className='smallText'>
									<li>
										<b>Data Encryption:</b> All data, including messages, user
										credentials, and personal information, is encrypted both in
										transit and at rest.
									</li>
									<li>
										<b>Access Control:</b> We provide strict user
										authentication, ensuring that only authorized users can
										access their data.
									</li>
									<li>
										<b>Regular Backups:</b> Your data is regularly backed up to
										prevent data loss and ensure it can be recovered in the
										event of a system failure.
									</li>
								</ul>
							}
						/>
						<CustomAccordion
							title='Can Goodbye App integrate with other services?'
							text='Yes! As part of our SaaS model, Goodbye App can integrate with third-party services to enhance functionality. For example, we integrate with Google services for authentication and email notifications. SaaS platforms often provide integration options with other cloud-based tools, offering a more versatile and connected experience.'
						/>

						<CustomAccordion
							title='How to get started with Goodbye App?'
							text={
								<span>
									Simply sign up on our website or app, create your profile, and
									start uploading your content. Our user-friendly interface
									ensures a seamless experience for individuals and businesses
									alike.
								</span>
							}
							component={
								<Link to='/signup'>
									<CustomButton
										variant='contained'
										startIcon={<AccountCircleIcon />}
										btnText={t('signup')}
										id='customInfoButton'
									/>
								</Link>
							}
						/>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
